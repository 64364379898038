import { Box, IconButton, Menu, MenuItem, Paper } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { CSSProperties, forwardRef, useState } from 'react';
interface SignatureFieldProps {
    top: string;
    left: string;
    onClick: () => void;
    onClearSignature: () => void;
    signatureDataUrl: string;
    isSigned?: boolean;
}

const SignatureField = forwardRef<HTMLDivElement, SignatureFieldProps>(
    (
        { top, left, onClick, onClearSignature, signatureDataUrl, isSigned },
        ref
    ) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const positionStyle: CSSProperties = {
            position: 'absolute',
            top: top,
            left: left,
            transform: 'translateY(-50%)',
            zIndex: 1000
        };

        // get screen width
        const screenWidth = window.innerWidth;

        // dynamically set the size of the signature field
        const iconPaperWidth =
            screenWidth >= 1200 ? screenWidth / 30 : screenWidth / 20;
        const iconPaperHeight =
            screenWidth >= 1200 ? screenWidth / 40 : screenWidth / 25;

        const imgWidth =
            screenWidth >= 1200 ? screenWidth / 10 : screenWidth / 6;

        const iconButtonSize = Math.min(iconPaperWidth, iconPaperHeight) * 0.7;
        const iconSize = iconButtonSize * 0.8;

        return (
            <>
                {signatureDataUrl && isSigned ? (
                    <Box
                        ref={ref}
                        component="div"
                        style={{
                            ...positionStyle,
                            border: '1px solid #ffd65b',
                            width: imgWidth,
                            cursor: 'pointer',
                            display: 'inline-block'
                        }}
                        onClick={handleClick}
                    >
                        <img
                            src={signatureDataUrl}
                            alt="signature"
                            width={imgWidth}
                        />
                    </Box>
                ) : (
                    <Paper
                        ref={ref}
                        elevation={3}
                        style={{
                            ...positionStyle,
                            backgroundColor: '#ffd65b',
                            border: '1px solid #b8860b',
                            width: `${iconPaperWidth}px`,
                            height: `${iconPaperHeight}px`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <IconButton
                            aria-label="signature"
                            onClick={onClick}
                            style={{
                                width: `${iconButtonSize}px`,
                                height: `${iconButtonSize}px`
                            }}
                        >
                            <BorderColorIcon
                                style={{
                                    fontSize: `${iconSize}px`,
                                    color: '#000000'
                                }}
                            />
                        </IconButton>
                    </Paper>
                )}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        dense
                        sx={{
                            color: 'primary.main',
                            padding: 1,
                            fontSize: 'small'
                        }}
                        onClick={() => {
                            onClearSignature();
                            handleClose();
                        }}
                    >
                        Clear Signature
                    </MenuItem>
                </Menu>
            </>
        );
    }
);
export default SignatureField;
