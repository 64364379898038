// IMPORTS
import { useContext, useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useParams, Prompt, useHistory } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import Trades from './components/Trades';
import Finalise from './components/Finalise';
import CustomerTable from '../../global/customerFormComponents/CustomerTable';
import SelectVehicle from './components/SelectVehicle';
import AddOns from './components/AddOns';
import Payment from './components/Payment';
import SpeedDial from '../../global/SpeedDial';
import SpeedDialList from './components/SpeedDialList';
import TextMessageDrawerContent from '../../global/TextMessageDrawerContent';
import Drawer from '../../global/Drawer';
import LogsDrawerContent from './components/LogsDrawerContent';
import PageDoesNotExist from '../../global/PageDoesNotExist';
// LOGIC
import GetContractDetails from './logic/GetContractDetails';
import GetVehicleAddedCosts from '../vehicle/logic/GetVehicleAddedCosts';
import GetAddedCostsTotals from '../vehicle/logic/GetAddedCostsTotals';
import SubmitVehicleSale from './logic/SubmitVehicleSale';
import UpdateVehicleSale from './logic/UpdateVehicleSale';
import PDFContract from './logic/PDFContract';
import PDFTaxInvoice from './logic/PDFTaxInvoice';
import GetDataFromLead from './logic/GetDataFromLead';
import PrintLOA from './logic/PrintLOA';
// INTERFACES
import {
    Customer,
    PaymentLine,
    Site,
    User
} from '../../global/interfaces/GeneralInterface';
import { Invoice, Order } from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import {
    ManufacturerVehicle,
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../global/interfaces/ServiceInterface';
import { SiteContext, UserContext } from '../../../main/Main';
import { withSnackbar } from '../../global/WrappingSnackbar';
import {
    GovernmentCharges,
    PartTotals,
    VoucherTotals
} from './logic/TotalFunctions';
import DealerSignLOAIndiv from '../signLOA/DealerSignLOAIndiv';
import DealerSignLOAOrg from '../signLOA/DealerSignLOAOrg';
import CheckCurrentUserSignature from '../../global/databaseLogic/CheckCurrentUserSignature';
import DealerContractActionDialog from '../../esign/components/DealerDocumentActionDialog';
interface VehicleAddedCosts {
    services: Service[];
    sales: Order[];
    invoices: Invoice[];
}

const NewVehicleSale = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const currentUser = useContext<User>(UserContext);
    const history = useHistory();

    const siteDetails = useContext<Site>(SiteContext);
    const leadId = new URLSearchParams(window.location.search).get('leadId');
    const [loading, setLoading] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState(true);
    const [isFinalised, setIsFinalsied] = useState(false);

    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [textDrawerOpen, setTextDrawerOpen] = useState<boolean>(false);
    const [taxInvoiceDialogOpen, setTaxInvoiceDialogOpen] =
        useState<boolean>(false);
    const [logsDrawerOpen, setLogsDrawerOpen] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);

    // print LOA dialog, select on be half of individual or organisation
    const [printLOADialogOpen, setPrintLOADialogOpen] =
        useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<null | string>(null);

    // sign LOA dialog, select on be half of individual or organisation
    const [signLOADialogOpen, setSignLOADialogOpen] = useState<boolean>(false);

    const [vehicleAddedCosts, setVehicleAddedCosts] =
        useState<VehicleAddedCosts>({ services: [], sales: [], invoices: [] });
    const [addedCostsTotal, setAddedCostsTotal] = useState({
        services: 0,
        sales: 0,
        invoices: 0
    });

    const [selectedCustomer, setSelectedCustomer] = useState<Customer>(null);
    const [selectedVehicle, setSelectedVehicle] = useState<
        Vehicle | ManufacturerVehicle
    >(null);
    const [paymentLines, setPaymentLines] = useState<PaymentLine[]>([]);
    const [linkedSales, setLinkedSales] = useState<Order[]>([]);
    const [linkedTrades, setLinkedTrades] = useState<TradeIn[]>([]);
    const [linkedVouchers, setLinkedVouchers] = useState<any[]>([]);
    const [gstType, setGstType] = useState<string>('INC');
    const [saleDetails, setSaleDetails] = useState<VehicleSale>({
        saleStatus: 'Sale',
        manufacturerOrder: false,
        includesOnRoadCosts:
            siteDetails.vehicleTypeSold === 'Motorcycles' ? true : false,
        excludeStampDuty: false,
        totalPurchasePrice: '0',
        warrantyPrice: '0',
        insurancePrice: '0',
        deliveryPrice: '0',
        registrationPrice: '0',
        ctpPrice: '0',
        transferPrice: '0',
        stampDutyPrice: '0',
        depositPrice: '0',
        paymentLines: [],
        contractStatus: null
    });

    const [oldSale, setOldSale] = useState<VehicleSale>({
        saleStatus: '',
        manufacturerOrder: false,
        includesOnRoadCosts:
            siteDetails.vehicleTypeSold === 'Motorcycles' ? true : false,
        excludeStampDuty: false,
        totalPurchasePrice: '0',
        warrantyPrice: '0',
        insurancePrice: '0',
        deliveryPrice: '0',
        registrationPrice: '0',
        ctpPrice: '0',
        transferPrice: '0',
        stampDutyPrice: '0',
        depositPrice: '0'
    });

    // Get the details of the sale if there is an id, otherwise set read only to false
    useEffect(() => {
        if (id) {
            GetContractDetails(
                parseInt(id),
                setSaleDetails,
                setSelectedCustomer,
                setSelectedVehicle,
                setPaymentLines,
                setLinkedSales,
                setLinkedTrades,
                setLinkedVouchers,
                setLoading,
                setGstType,
                showSnackbar,
                setInvalid,
                setOldSale,
                setIsFinalsied
            );
        } else if (leadId) {
            GetDataFromLead(leadId, setSelectedCustomer);
            setReadOnly(false);
        } else {
            setReadOnly(false);
            setShouldBlockNavigation(true);
        }
        // eslint-disable-next-line
    }, [id, leadId]);

    // When the selected vehicle changes if there is no id
    // then preset the vehicle sale price to the selected
    // vehicle RRP. Regardless of ID get all the add ons
    useEffect(() => {
        if (selectedVehicle?.id) {
            if (!id) {
                saleDetails.vehicleSalePrice = selectedVehicle?.priceRRP;
            }
            GetVehicleAddedCosts(selectedVehicle?.id, setVehicleAddedCosts);
        }
        // eslint-disable-next-line
    }, [selectedVehicle]);

    // Once all the add ons have been retrieved calculate the totals
    useEffect(() => {
        GetAddedCostsTotals(
            selectedVehicle,
            vehicleAddedCosts,
            setAddedCostsTotal
        );
    }, [selectedVehicle, vehicleAddedCosts]);

    // If there is no id then we want to update the total purchase price
    // ny time something else changes to ensure it all stays up to date
    useEffect(() => {
        if (!id) {
            let totalPurchasePrice =
                parseFloat(saleDetails.vehicleSalePrice) +
                parseFloat(saleDetails.warrantyPrice) +
                parseFloat(saleDetails.insurancePrice) +
                parseFloat(saleDetails.deliveryPrice) +
                parseFloat(saleDetails.registrationPrice) +
                parseFloat(saleDetails.ctpPrice) +
                parseFloat(saleDetails.stampDutyPrice) +
                parseFloat(saleDetails.transferPrice);

            totalPurchasePrice = parseFloat(totalPurchasePrice.toFixed(2));

            setSaleDetails({
                ...saleDetails,
                totalPurchasePrice: String(totalPurchasePrice)
            });
        }
        // eslint-disable-next-line
    }, [
        id,
        saleDetails.vehicleSalePrice,
        saleDetails.warrantyPrice,
        saleDetails.insurancePrice,
        saleDetails.deliveryPrice,
        saleDetails.registrationPrice,
        saleDetails.ctpPrice,
        saleDetails.stampDutyPrice,
        saleDetails.transferPrice
    ]);

    // Handles submitting a sale
    const handleSubmitSale = () => {
        if (id) {
            UpdateVehicleSale(
                parseInt(id),
                saleDetails,
                selectedCustomer,
                selectedVehicle,
                paymentLines,
                linkedTrades,
                linkedVouchers,
                linkedSales,
                showSnackbar,
                gstType
            );
        } else {
            SubmitVehicleSale(
                parseInt(id),
                saleDetails,
                selectedCustomer,
                selectedVehicle,
                paymentLines,
                linkedTrades,
                linkedVouchers,
                showSnackbar,
                gstType
            );
        }
    };

    const handlePrintContract = (fillInformation: boolean) => {
        PDFContract(
            saleDetails,
            selectedCustomer,
            selectedVehicle,
            linkedSales,
            linkedTrades,
            linkedVouchers,
            paymentLines,
            fillInformation,
            null
        );
    };

    const handlePrintTaxInvoice = (paymentType: string) => {
        PDFTaxInvoice(
            paymentType,
            saleDetails,
            selectedCustomer,
            selectedVehicle,
            linkedSales,
            linkedTrades,
            linkedVouchers,
            paymentLines
        );
    };

    let partTotals = PartTotals(linkedSales);
    let voucherTotals = VoucherTotals(linkedVouchers);
    let governmentCharges = GovernmentCharges(saleDetails);

    // dutyPrice = Total Purchase Price - Government Charges - warranty - delivery - DOF - Add-Ons
    let dutyPrice =
        parseFloat(saleDetails.totalPurchasePrice) -
        governmentCharges -
        parseFloat(saleDetails.warrantyPrice) -
        parseFloat(saleDetails.deliveryPrice) -
        parseFloat(saleDetails.insurancePrice) -
        partTotals.totalPartPrice -
        voucherTotals;

    const handlePrintLOA = (customerType: string) => {
        PrintLOA(customerType, saleDetails, dutyPrice, setLoadingButton);
    };

    const [contractActionDialogOpen, setContractActionDialogOpen] =
        useState<boolean>(false);

    const navigateToDealerSignContract = () => {
        window.open(`/docs/vehicle-contract/${saleDetails.id}`, '_blank');
    };

    const handleOpenContract = () => {
        // only  proceed to next step if the current step is completed
        CheckCurrentUserSignature({
            currentUser: currentUser,
            showSnackbar: showSnackbar,
            history: history
        }).then(async (base64Image) => {
            if (saleDetails.contractStatus === null) {
                await PDFContract(
                    saleDetails,
                    selectedCustomer,
                    selectedVehicle,
                    linkedSales,
                    linkedTrades,
                    linkedVouchers,
                    paymentLines,
                    true,
                    base64Image as string,
                    true
                );
                navigateToDealerSignContract();
            } else {
                setContractActionDialogOpen(true);
            }
        });
    };

    // const handleClickStartOver = () => {
    //     PDFContract(
    //         saleDetails,
    //         selectedCustomer,
    //         selectedVehicle,
    //         linkedSales,
    //         linkedTrades,
    //         linkedVouchers,
    //         paymentLines,
    //         true,
    //         true
    //     );
    //     navigateToDealerSignContract();
    //     setLoadingButton(null);
    // };

    const handleClickViewContract = () => {
        navigateToDealerSignContract();
        setLoadingButton(null);
    };

    let customerTab = {
        id: 0,
        title: 'Select Customer',
        content: (
            <>
                <CustomerTable
                    readOnly={
                        readOnly ||
                        isFinalised ||
                        saleDetails.paymentLines.length > 0
                    }
                    vehicleSaleDetails={saleDetails}
                    selectedEntity={selectedCustomer}
                    setSelectedEntity={setSelectedCustomer}
                    includeShippingDrawer={false}
                />
                {selectedCustomer?.id ? (
                    <SpeedDial
                        actionsList={SpeedDialList(
                            readOnly,
                            setReadOnly,
                            setLogsDrawerOpen,
                            saleDetails,
                            handleSubmitSale,
                            isFinalised,
                            setTextDrawerOpen,
                            handlePrintContract,
                            setTaxInvoiceDialogOpen,
                            setPrintLOADialogOpen,
                            setSignLOADialogOpen,
                            handleOpenContract
                        )}
                    />
                ) : null}
            </>
        )
    };

    let vehicleTab = {
        id: 1,
        title:
            siteDetails.vehicleTypeSold === 'Motorcycles'
                ? 'Select Vehicle'
                : 'Select Bicycle',
        content: (
            <>
                <SelectVehicle
                    readOnly={readOnly || isFinalised}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                />
                {selectedVehicle?.id ? (
                    <SpeedDial
                        actionsList={SpeedDialList(
                            readOnly,
                            setReadOnly,
                            setLogsDrawerOpen,
                            saleDetails,
                            handleSubmitSale,
                            isFinalised,
                            setTextDrawerOpen,
                            handlePrintContract,
                            setTaxInvoiceDialogOpen,
                            setPrintLOADialogOpen,
                            setSignLOADialogOpen
                        )}
                    />
                ) : null}
            </>
        )
    };

    let addOnTab = {
        id: 2,
        title: 'Add-Ons',
        content: (
            <>
                <AddOns
                    readOnly={readOnly || isFinalised}
                    saleDetails={saleDetails}
                    linkedSales={linkedSales}
                    setLinkedSales={setLinkedSales}
                    linkedVouchers={linkedVouchers}
                    setLinkedVouchers={setLinkedVouchers}
                    linkedTrades={linkedTrades}
                    gstType={gstType}
                />
                <SpeedDial
                    actionsList={SpeedDialList(
                        readOnly,
                        setReadOnly,
                        setLogsDrawerOpen,
                        saleDetails,
                        handleSubmitSale,
                        isFinalised,
                        setTextDrawerOpen,
                        handlePrintContract,
                        setTaxInvoiceDialogOpen,
                        setPrintLOADialogOpen,
                        setSignLOADialogOpen
                    )}
                />
            </>
        )
    };

    let tradesTab = {
        id: 3,
        title: 'Trades',
        content: (
            <>
                <Trades
                    readOnly={readOnly || isFinalised}
                    saleDetails={saleDetails}
                    linkedSales={linkedSales}
                    linkedVouchers={linkedVouchers}
                    linkedTrades={linkedTrades}
                    setLinkedTrades={setLinkedTrades}
                    showSnackbar={showSnackbar}
                    gstType={gstType}
                    setGstType={setGstType}
                />
                <SpeedDial
                    actionsList={SpeedDialList(
                        readOnly,
                        setReadOnly,
                        setLogsDrawerOpen,
                        saleDetails,
                        handleSubmitSale,
                        isFinalised,
                        setTextDrawerOpen,
                        handlePrintContract,
                        setTaxInvoiceDialogOpen,
                        setPrintLOADialogOpen,
                        setSignLOADialogOpen
                    )}
                />
            </>
        )
    };

    let finaliseTab = {
        id: 4,
        title: 'Finalise',
        content: (
            <>
                <Finalise
                    readOnly={readOnly}
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                    oldSale={oldSale}
                    selectedVehicle={selectedVehicle}
                    addedCostsTotal={addedCostsTotal}
                    linkedSales={linkedSales}
                    linkedVouchers={linkedVouchers}
                    linkedTrades={linkedTrades}
                    setLinkedTrades={setLinkedTrades}
                    isFinalised={isFinalised}
                    setIsFinalised={setIsFinalsied}
                    gstType={gstType}
                />
                <SpeedDial
                    actionsList={SpeedDialList(
                        readOnly,
                        setReadOnly,
                        setLogsDrawerOpen,
                        saleDetails,
                        handleSubmitSale,
                        isFinalised,
                        setTextDrawerOpen,
                        handlePrintContract,
                        setTaxInvoiceDialogOpen,
                        setPrintLOADialogOpen,
                        setSignLOADialogOpen
                    )}
                />
            </>
        )
    };

    let paymentTab = {
        id: 5,
        title: 'Payment',
        content: (
            <>
                <Payment
                    id={parseInt(id)}
                    readOnly={readOnly}
                    isFinalised={isFinalised}
                    saleDetails={saleDetails}
                    paymentLines={paymentLines}
                    setPaymentLines={setPaymentLines}
                    showSnackbar={showSnackbar}
                    selectedCustomer={selectedCustomer}
                    linkedSales={linkedSales}
                    linkedVouchers={linkedVouchers}
                    linkedTrades={linkedTrades}
                    gstType={gstType}
                />
                <SpeedDial
                    actionsList={SpeedDialList(
                        readOnly,
                        setReadOnly,
                        setLogsDrawerOpen,
                        saleDetails,
                        handleSubmitSale,
                        isFinalised,
                        setTextDrawerOpen,
                        handlePrintContract,
                        setTaxInvoiceDialogOpen,
                        setPrintLOADialogOpen,
                        setSignLOADialogOpen
                    )}
                />
            </>
        )
    };

    let tabContent = [];

    if (saleDetails.saleStatus === 'Quote') {
        tabContent = [
            customerTab,
            vehicleTab,
            addOnTab,
            tradesTab,
            finaliseTab
        ];
    } else {
        tabContent = [
            customerTab,
            vehicleTab,
            addOnTab,
            tradesTab,
            finaliseTab,
            paymentTab
        ];
    }

    const [showDealerSignIndiv, setShowDealerSignIndiv] =
        useState<boolean>(false);
    const [showDealerSignOrg, setShowDealerSignOrg] = useState<boolean>(false);

    const handleOpenIndividualLOA = () => {
        setShowDealerSignIndiv(true);
    };

    const handleOpenOrgLOA = () => {
        setShowDealerSignOrg(true);
    };

    if (showDealerSignIndiv) {
        return (
            <DealerSignLOAIndiv
                dutyPrice={dutyPrice}
                saleDetails={saleDetails}
                showSnackbar={showSnackbar}
            />
        );
    }

    if (showDealerSignOrg) {
        return (
            <DealerSignLOAOrg
                dutyPrice={dutyPrice}
                saleDetails={saleDetails}
                showSnackbar={showSnackbar}
            />
        );
    }

    return (
        <PageWrapper>
            {loading ? (
                <Typography align="center">
                    <CircularProgress />
                </Typography>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Prompt
                        when={shouldBlockNavigation}
                        message="You have unsaved changes, are you sure you want to leave?"
                    />
                    <Typography variant="h4">
                        {id ? `Contract #${id}` : 'New Contract'}
                    </Typography>
                    <br />
                    <Tabs tabContent={tabContent} />
                </>
            )}

            <Drawer
                openDrawer={textDrawerOpen}
                setOpenDrawer={setTextDrawerOpen}
                title="New Text Message"
                subTitle={
                    selectedCustomer?.firstName +
                    ' ' +
                    selectedCustomer?.lastName
                }
            >
                <TextMessageDrawerContent
                    id={parseInt(id)}
                    department="Sales"
                    customerName={
                        selectedCustomer?.firstName +
                        ' ' +
                        selectedCustomer?.lastName
                    }
                    customerPhone={selectedCustomer?.phoneNumber}
                    showSnackbar={showSnackbar}
                />
            </Drawer>

            <Dialog
                open={taxInvoiceDialogOpen}
                onClose={() => setTaxInvoiceDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Print Tax Invoice</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" align="center">
                        What payment type would you like to generate the tax
                        invoice using?
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6} textAlign="right">
                            <Button
                                variant="outlined"
                                onClick={() => handlePrintTaxInvoice('deposit')}
                            >
                                Deposit
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign="left">
                            <Button
                                variant="outlined"
                                onClick={() => handlePrintTaxInvoice('payment')}
                            >
                                Payments
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={printLOADialogOpen}
                onClose={() => setPrintLOADialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Print Letter of Authority </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" align="center">
                        Please select the type of customer you are printing the
                        LOA.
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6} textAlign="right">
                            <LoadingButton
                                loading={loadingButton === 'individual'}
                                variant="outlined"
                                onClick={() => {
                                    handlePrintLOA('individual');
                                    setLoadingButton('individual');
                                }}
                            >
                                Individuals
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={6} textAlign="left">
                            <LoadingButton
                                loading={loadingButton === 'organisation'}
                                variant="outlined"
                                onClick={() => {
                                    handlePrintLOA('organisation');
                                    setLoadingButton('organisation');
                                }}
                            >
                                Organisations
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={signLOADialogOpen}
                onClose={() => setSignLOADialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Send LOA for Signature</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" align="center">
                        Please select the type of customer to whom you are
                        sending the LOA.
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6} textAlign="right">
                            <LoadingButton
                                loading={loadingButton === 'individual'}
                                variant="outlined"
                                onClick={handleOpenIndividualLOA}
                            >
                                Individuals
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={6} textAlign="left">
                            <LoadingButton
                                loading={loadingButton === 'organisation'}
                                variant="outlined"
                                onClick={handleOpenOrgLOA}
                            >
                                Organisations
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <DealerContractActionDialog
                dialogOpen={contractActionDialogOpen}
                setDialogOpen={setContractActionDialogOpen}
                status={saleDetails.contractStatus}
                handleClickView={handleClickViewContract}
            />

            <Drawer
                openDrawer={logsDrawerOpen}
                setOpenDrawer={setLogsDrawerOpen}
                title="Change History"
                subTitle=""
                width="40vw"
            >
                <LogsDrawerContent id={parseInt(id)} />
            </Drawer>
        </PageWrapper>
    );
};

export default withSnackbar(NewVehicleSale);
