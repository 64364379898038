import { getDocument } from 'pdfjs-dist';
import { GetFileUrlFromS3 } from './S3FileService';

const LoadDocumentOnCanvas = async ({
    folderName,
    fileName,
    canvasRefs,
    setPdfLoaded
}) => {
    try {
        const url = await GetFileUrlFromS3(folderName, fileName);
        const pdf = await getDocument(url).promise;
        const numPages = pdf.numPages;

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 1 });
            const canvas = canvasRefs.current[pageNum - 1];

            if (canvas) {
                const context = canvas.getContext('2d');

                // Obtain the size of the canvas as styled by CSS
                const rect = canvas.getBoundingClientRect();

                // Calculate the scale based on the actual canvas width
                const scale = rect.width / viewport.width;

                const outputScale = (rect.width / viewport.width) * 3;

                // Set canvas internal size adjusted for device pixel ratio
                canvas.width = viewport.width * outputScale;
                canvas.height = viewport.height * outputScale;

                // Adjust CSS size to fit the container
                canvas.style.height = `${viewport.height * scale}px`;

                const enhancedViewport = page.getViewport({
                    scale: outputScale
                });

                // Render the page on the canvas
                await page.render({
                    canvasContext: context,
                    viewport: enhancedViewport
                }).promise;
            }
        }

        setPdfLoaded(true);
        return numPages;
    } catch (error) {
        console.error('Error loading PDF document:', error);
        setPdfLoaded(false);
        return 0;
    }
};

export default LoadDocumentOnCanvas;
