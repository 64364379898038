import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerSubmitSuccessPage from '../../esign/components/CustomerSubmitSuccessPage';
import CustomerSignContract from './CustomerSignContract';
import { withSnackbar } from '../../global/WrappingSnackbar';
import GetDocumentInfo from '../../esign/logic/GetDocumentInfo';
import { RequiredField } from '../../esign/EsignInterface';
import ClearTokenOnExit from '../../esign/logic/CleanTokenOnExit';

const CustomerContractPage = ({ showSnackbar }) => {
    let { id: vehicleSaleId } = useParams<{ id: string }>();

    const [customerInfo, setCustomerInfo] = useState(null);
    const [contractStatus, setcontractStatus] = useState(null);

    // list all required fields, each field has an id, ref, sign status, page number, and position
    const contractRequiredFields = [
        {
            id: 0,
            name: 'creditSig',
            ref: useRef(null),
            required: true,
            signed: false,
            pageNo: 0,
            top: '53%',
            left: '64%'
        },
        {
            id: 1,
            name: 'declarationSig',
            ref: useRef(null),
            required: true,
            signed: false,
            pageNo: 1,
            top: '86%',
            left: '18%'
        }
    ];

    // delivery receipt required fields
    const deliveryReceiptFields = [
        {
            id: 0,
            name: 'deliverySig',
            ref: useRef(null),
            required: true,
            signed: false,
            pageNo: 0,
            top: '68%',
            left: '64%'
        }
    ];

    const [requiredFields, setRequiredFields] = useState<RequiredField[]>([]);

    const getContractInfo = async () => {
        const res = await GetDocumentInfo({
            documentType: 'contract',
            vehicleSaleId,
            showSnackbar
        });
        setCustomerInfo(res.customerInfo);
        setcontractStatus(res.contractStatus);
        setRequiredFields(
            res.contractStatus === 'waitingSignature'
                ? contractRequiredFields
                : res.contractStatus === 'waitingDeliverySignature'
                  ? deliveryReceiptFields
                  : []
        );
    };

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line
    }, [vehicleSaleId]);

    // Remove auth token from local storage when the user closes the tab or refreshes the page, user will have to sign again
    useEffect(() => {
        ClearTokenOnExit();
    }, []);

    return (
        <>
            {contractStatus === 'waitingSignature' ||
            contractStatus === 'waitingDeliverySignature' ? (
                <CustomerSignContract
                    customerInfo={customerInfo}
                    contractStatus={contractStatus}
                    requiredFields={requiredFields}
                    setRequiredFields={setRequiredFields}
                    showSnackbar={showSnackbar}
                />
            ) : contractStatus === 'customerSigned' ||
              contractStatus === 'deliverySigned' ? (
                <CustomerSubmitSuccessPage />
            ) : null}
        </>
    );
};

export default withSnackbar(CustomerContractPage);
