import { Paper } from '@material-ui/core';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

interface SignatureDisplayProps {
    name: string;
    setTempSignatureDataUrl: React.Dispatch<React.SetStateAction<string>>;
}

const SelectSignature = ({
    name,
    setTempSignatureDataUrl
}: SignatureDisplayProps) => {
    const selectedFont = '"Great Vibes", cursive';

    // generate sig data url from text amd selected font
    const generateSignatureDataUrl = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
            //initial canvas size
            canvas.width = 500;
            canvas.height = 150;

            //initial font size
            let fontSize = 50;
            context.font = `${fontSize}px ${selectedFont}`;

            // measure text width
            let metrics = context.measureText(name);

            // Adjust canvas width if text is too long
            if (metrics.width > canvas.width - 20) {
                canvas.width = metrics.width + 20;
            }

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = '#000';

            context.font = '50px ' + selectedFont;
            context.fillText(
                name,
                (canvas.width - metrics.width) / 2,
                canvas.height - 10
            );
            return canvas.toDataURL();
        }
    };

    useEffect(() => {
        const dataUrl = generateSignatureDataUrl();
        setTempSignatureDataUrl(dataUrl);
        // eslint-disable-next-line
    }, [selectedFont]);

    return (
        <>
            {/* <Button>Change Style</Button> */}
            <Paper elevation={3} style={{ padding: 30 }}>
                <Typography
                    variant="h5"
                    style={{ fontFamily: selectedFont, fontSize: '24px' }}
                >
                    {name}
                </Typography>
            </Paper>
        </>
    );
};

export default SelectSignature;
