import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid
} from '@mui/material';

interface DealerDocumentActionDialogProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    status: string;
    handleClickView: () => void;
}

const DealerDocumentActionDialog = ({
    dialogOpen,
    setDialogOpen,
    status,
    handleClickView
}: DealerDocumentActionDialogProps) => {
    return (
        <Dialog
            open={dialogOpen && status !== null}
            onClose={() => setDialogOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Email Contract To Sign </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {status === 'waitingSignature' &&
                        'This document has already been emailed to the customer for their signature.'}
                    {status === 'customerSigned' &&
                        'The contract Credit and Declaration sections have been signed by the customer.'}
                    {status === 'deliverySigned' &&
                        'Delivery Receipt has been signed.'}
                    {/* If you need to update and resend the contract, please
                        click the Start Over button. Note that doing so will
                        erase all previous changes. */}
                    <br />
                    <br />
                    {`To review the current contract${status === 'customerSigned' ? ' and/or email the Delivery Receipt to the customer for their signature' : ''}, click View Contract.`}
                </Typography>

                <br />
                <Grid container spacing={2}>
                    {/* <Grid item xs={6} textAlign="right">
                            <LoadingButton
                                loading={loadingButton === 'startOver'}
                                variant="outlined"
                                onClick={() => {
                                    handleClickStartOver();
                                    setLoadingButton('startOver');
                                }}
                            >
                                Start Over
                            </LoadingButton>
                        </Grid> */}
                    <Grid item xs={12} textAlign="center">
                        <LoadingButton
                            // loading={loadingButton === 'viewContract'}
                            variant="outlined"
                            onClick={() => {
                                handleClickView();
                                // setLoadingButton('viewContract');
                            }}
                        >
                            View Contract
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DealerDocumentActionDialog;
