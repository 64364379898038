import api from '../../../../api';

const CheckCurrentUserSignature = ({ currentUser, showSnackbar, history }) => {
    return new Promise((resolve) => {
        api.get('getUserSignature')
            .then((res) => {
                if (res.status === 200) {
                    const base64Image = res.data;
                    resolve(base64Image);
                } else if (res.status === 404) {
                    showSnackbar(
                        'Your user signature is required to proceed',
                        'Please add your signature and try again. You will be automatically redirected to the user details page in 3 seconds.',
                        'error'
                    );

                    setTimeout(() => {
                        history.push('/viewUser/' + currentUser.id);
                    }, 3000);
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact the IT department.',
                        'error'
                    );
                }
            })
            .catch((error) => {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            });
    });
};

export default CheckCurrentUserSignature;
